<app-layout>
  <!--[containerClass]="containerClass"-->
  <div class="flex flex-column align-items-stretch flex-grow-1 surface-ground">
    <router-outlet></router-outlet>
  </div>
  <div class="layout-footer surface-ground flex justify-content-center h-4rem">
    <img src="images/brand.svg" />
  </div>
</app-layout>

